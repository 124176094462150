@import './colors.scss';

.overlay {
  height:  100%;
  width: 100%;
  position: fixed;
}

.options {
  height: max-content;
  position: fixed;
  right: 20px;
  width: 200px;
  border-radius: 20px;
  background-color: #eee;
  z-index: 50;

  li {
    min-height: 44px;
    line-height: 1.3;
    padding: 10px;
    background-color: #fff;
    margin-bottom: 1px;
    cursor: pointer;

    &:hover {
      background-color: #043d6d;
      color: #fff;
    }
  }
}

.searchContainer {
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 10px;
  margin-bottom: 20px;


  .search, .multiOptions  {
    background-color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: #444;
  }

  .search {
    justify-content: space-between;
    position: relative;

    input {
      padding-left: 20px;
      background-color: inherit;
      margin-bottom: inherit;
    }

    i {
      position: absolute;
      right: 20px;
    }
  }

  .multiOptions {
    justify-content: center;
  }
}


.notifications {
  max-height: 70vh;
  overflow: auto;
}

.notification {
  background-color: #f4f4f4;
  display: flex;
  padding: 20px 0px 20px 20px;
  align-items: center;
  border-radius: 10px;
  color: #444;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 50px 1fr 210px;
  position: relative;

  .exclamation {
    position: absolute;
    color: red;
    left: 12px;
  }

  i {
    font-size: 1.3rem;
  }

  .information {
    display: flex;
    flex-direction: column;
    padding-right: 20px;

    .type {
      margin-bottom: 10px ;
    }

    .date {
      margin-left: 20px;
    }
    
    strong {
      font-size: 1.1rem;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }

  .message {
    line-height: 1.3;
  }

  .accept {
    color: #15873C;
  }

  .decline {
    color: #ED3232;
  }

  .actions {
    display: grid;
    grid-template-columns: repeat(3, 70px);

    i {
      cursor: pointer;
    }
  }
}


@media only screen and (max-width: 650px) {
  .notification {
    grid-template-columns: 1fr;
    grid-gap: 10px;

    .exclamation {
      top: 20px;
    }

    .multiOptions {
      position: absolute;
      right: 30px;
      top: 20px
    }

    .spacer {
      display: none;
    }

    .type {
      display: flex;
      flex-direction: column;

      .date {
        margin-left: 0;
      }
    }
  }
}
