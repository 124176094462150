
.footer {
  color: #fff;
  background-color: #009fe2;
  text-align: left;
  margin-top: 60px;
  padding: 20px;


  *.disabled {
    opacity: 0.5;
  }

  .footer-links ul li a {
    color: white !important;
  }

  .footer-links ul li a:hover {
    border-bottom: 1px solid grey;
    color: grey;
  }

  .copyright {
    text-align: left;
    width: 80%;
    margin: auto;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-top: 10px;
  }

  .footerInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
    border-bottom: 1px solid white;

    .links {
      margin-bottom: 20px;
      li {
        cursor: pointer;
        padding: 2px;
      }
    }
  }
}


// iphone 5
@media only screen and (max-width: 600px) {
  .footerInfo {
    flex-direction: column;
  }

  .copyright {
    flex-direction: column;

    span:last-of-type {
      margin-top: 10px;
    }
  }
}
