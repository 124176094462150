@import './colors.scss';

.credits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.credit {
  background-color: $background;
  color: #444;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  flex-direction: column;
  font-size: 16px;

  .iconContainer {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: $color-dark;
    i {
      color: #fff;
      font-size: 2.7rem;
    }
  }

  .type {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .amount {
    margin-top: 20px;
    font-size: 20px;
  }
}


.count {
  margin-left: 1rem;
}

.link {
  background-color: #f5f5f5;

  a {
    color: #000;
    text-decoration: none;
  }
}

.transferingContainer {
  min-height: inherit !important;
  border: 1px solid red;
}

.transfering {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-bottom: 0;
    }
  }

  .cancel {
    color: red;
    cursor: pointer;
  }

  i {
    margin-right: 15px;
  }
}


.error {
  background-color:  $warning;
}

.placeholder {
  background-color: #f9f9f9;
  text-align: center;
}


.siteSharedUser {
  background-color: #f9f9f9;
  min-height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 6px;

  .actions {
    display: flex;
    gap: 5px;

    :global {
      .container {
        margin-top: 6px;
      }
    }

    .trashContainer {
      background-color: #fff;
      border-radius: 5px;
      width: 40px;
      cursor: pointer;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #444;

      &:hover {
        background-color: #ccc;
      }
    }
  }

  .name {
    display: flex;
    flex-direction: column;
    strong {
      color: #0595E5;
      font-size: 1rem;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  i {
    cursor: pointer;
  }


  :global {
    .checkmark {
      background-color: #fff;
    }
  }
}

.addSharedUser {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 44px;
  padding: 20px;
  align-items: center;
  min-height: 44px;
  border-radius: 6px;
  position: relative;
  background-color: #f9f9f9;

  i {
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    margin-bottom: 0;
    background-color: #f9f9f9;
  }
}

.sharedUsers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  grid-gap: 20px
}

.devices {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 20px
}

.device {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 80px;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 20px;
  position: relative;

  .actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;

    i {
      cursor: pointer;
    }
  }

  .offline {
    background-color: $warning;
    width: 5px;
    display: block;
    min-height: 50px;
    position: absolute;
  }

  .online {
    background-color: green;
    width: 5px;
    display: block;
    min-height: 50px;
    position: absolute;
  }

  span {
    &:first-of-type {
      font-size: 1.3rem;
      color: $color-dark;
      font-weight: 500;
    }

  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
}

.sharedUser {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 20px;
  position: relative;

  span {
    &:first-of-type {
      font-size: 1.3rem;
      color: $color-dark;
      font-weight: 500;
    }

  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  .sharedUserDevices {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    i {
      margin-right: 10px;
      color: $color-dark;
    }

    span {
      font-size: 0.9rem;
    }
  }

  .invited {
    color: #008fff;
    cursor: pointer;

    span {
      font-weight: 300;
      font-size: 1rem;
      margin-bottom: 0;
      color: #008fff;
    }
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }


  div {
    display: flex;
    min-height: 44px;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;

    span {
      margin-bottom: 0;
    }

    i {
      margin-right: 15px;
      cursor: pointer;
    }
  }
}

.container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  min-height: 200px;

  input {
    background-color: #f5f5f5;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    :global {
      .btn {
        padding: 0 5px;
        background-color: #fff;
        color: #008fff;
        margin-bottom: 15px;
        width: min-content;

        span {
          margin-bottom: 0;
        }

        i {
          margin-right: 10px;
        }
      }
    }
  }

  h2 {
    font-size: 1.3rem;
    font-weight: 500;
    color: #444;
    display: flex;

    i {
      margin-right: 20px;
    }
  }

  span {
    display: block;
    margin-bottom: 10px;
  }
}


.enabled, .disabled {
  padding: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  background-color: #f9f9f9;
}

@media only screen and (max-width: 1000px) {
  .sharedUsers {
    grid-template-columns: 1fr;
  }

  .credits {
    grid-template-columns: 1fr;
  }
}


@media only screen and (max-width: 650px) {

  .transfering {
    padding-bottom: 0;
    position: relative;
    flex-direction: column;

    i {
      position: absolute;
      left: 0px;
      top: 0px;
    }

    span {
      margin-top: 20px;
    }

  }

  .sharedUsers {
    grid-template-columns: 1fr;
  }

  .devices {
    grid-template-columns: 1fr;
  }

  .cancel {
    margin-top: 20px;
  }
}
