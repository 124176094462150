@import './colors.scss';

.sharedUser {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 120px;
  border-radius: 8px;
  background-color: #fff;

  input {
    margin-bottom: 0;
    border-radius: 0px;
    outline: none;
    border-bottom: 0px !important;
    height: inherit;
    padding: 0px;
    margin-bottom: 8px;
    margin-top: -2px;

    &:first-of-type {
      font-size: 1.3rem;
      height: inherit;
      color: $color-dark;
      font-weight: 500;
    }
  }

  .editing {
    cursor: disabled;
  }

  .information {
    display: grid;
    align-items: center;
    padding: 20px;

    span {
      margin-bottom: 10px;
    }

    .invited {
      color: #008fff;
      font-weight: 300;
      font-size: 1rem;
      cursor: pointer;
    }

    .name {
      font-size: 1.3rem;
      color: $color-dark;
      font-weight: 500;
      display: flex;
      align-items: center;

    }
  }

  .actions {
    display: grid;
    grid-template-columns: repeat(2, 60px);
    i {
      cursor: pointer;
    }
  }

  i {
    margin-right: 15px;
  }

}

.sharedUsersModal {
  grid-template-rows: 60px 1fr;


  .sharedUsers {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    grid-gap: 20px;
  }
}

.newSharedUser {
  @extend .sharedUser;
  grid-template-columns: 1fr 60px;

  .inputs {
    padding: 15px;
  }

  i {
    cursor: pointer;
  }

  input {
    margin-bottom: 0;
    border-radius: 0px;
    outline: none;
    border-bottom: 0px !important;
    margin-top: inherit;
    padding: 0 8px;
    height: 35px;
    

    &:first-of-type {
      font-size: 1.3rem;
      height: inherit;
      color: $color-dark;
      font-weight: 500;
    }
  }

}
@media only screen and (max-width: 500px) {
  .sharedUser, .newSharedUser {
    grid-template-columns: 1fr;

    .information {
      padding-bottom: 0;
    }

    .actions {
      padding: 20px;
    }
  }

  .newSharedUser {
    i {
      padding: 20px;
    }
  }
};
