@import './colors.scss';

.overlay {
  min-height: 100vh;
  background-color: black;
  opacity: 0.7;
  position: fixed;
  z-index: 1000;
  width: 100%;
  left: 0;
  top: 0;
}

.modal {
  background-color: #fafafa;
  width: 70%;
  border-radius: 5px;
  z-index: 15000;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  transform: translate(-50%, -50%); /* This is a shorthand of
  translateX(-50%) and translateY(-50%) */
  position: fixed;
  display: grid;
  grid-template-rows: 60px 1fr 70px;
  max-width: 1000px;

  :global {

    .modal-content {
      width: 100%;
      margin: 0px auto;
      padding: 20px;
      max-height: 80vh;
      overflow: auto;
    }

    .modal-footer  {
      width: 90%;
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      height: 70px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      width: 100%;
      padding: 0 20px;


      display: flex;
      justify-content: flex-end;

      .btn {
        margin-right: 10px;
        min-width: 150px;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
        background-color: #000;
        color: #fff;

        &:last-of-type {
          margin-right: 0;
          background-color: #eee;
          color: #000;
        }
      }
    }

    .modal-header {
      background-color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 55px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      &.error {
        background-color: red;
      }

      i {
        margin-right: 20px;
        font-size: 1rem;
        color: #fff;
        cursor: pointer;
      }

      h1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        justify-content: space-between;
        color: #fff;
        margin-top: 0;
        font-weight: inherit;
        border-bottom: inherit;
        font-size: 1.4rem;
        margin-bottom: 0;
        margin-left: 20px;

        span, i {
          margin: 10px;
        }
      }
    }

  }
}

:global {
  #warningModal {
    z-index: 15005;
    width: 70%;
    bottom: auto;

    .modal-content {
      p {
        line-height: 1.3;
      }
    }

    .modal-header {
      background-color: $warning;
    }

    .modal-footer {
      .btn {
        &:first-of-type {
          background-color: $warning;
        }

      }
    }
  }
}

// ipad landscape mode
@media only screen and (max-width: 1200px) {
  .modal {
    margin-left: 0;
  }

}

// ipad normal view
@media only screen and (max-width: 768px) {

}

// iphone 7/8 plus
@media only screen and (max-width: 800px) {

  :global {
    #warningModal {
      z-index: 15005;
      height: 200px;
      width: max-content;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;

    }
  }

  .modal {
    margin-left: 0;
    width: 100%;
    height: 100%;

    :global {

      .modal-content {
        max-height: 95vh;
      }

      .modal-footer  {
        .btn {
          min-width: inherit;
        }
      }
    }
  }

  :global {
    #warningModal {
      top: 0;
      left: 0;
      width: 100%;
    }
  }
};


