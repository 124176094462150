@import './colors.scss';

.page {
  margin-top: 70px;
}

.collapsibleBody {
  background-color: $background-accent;
  border-top: 1px solid $background;
  padding: 20px;
  margin-bottom: 1px;
};

.collapsibleHeader {
  padding: 10px;
  background-color: $background-accent;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  height: 44px;
  border-bottom: 1px solid $background;
  align-items: center;
}

.goToLogin {
  text-align: center;
  color: $color-light;
  margin-top: 20px;
  cursor: pointer;
}

.legalDocument {

  * {
    padding: 5px;
    padding-left: 0;
    line-height: 1.3;
  }

  h1, h2, h3, h4 {
    font-weight: 500;
    color: $sidenav-active;
  }
}

