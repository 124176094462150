.options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-top: 40px;

  :global {
    label {
      margin-bottom: 0;
      font-weight: initial;
    }
  }

  .option {
    min-height: 100px;
    background-color: #eee;
    color: #000;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #d1dde5;
    }
  }
}
