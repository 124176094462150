$footer: #009FE2;
$body: #fff;
$nav: #000;
$nav-text: #fff;
$footer-text: #000;

$warning: #ff3c3c;

$label-color: #000;
$color-dark: #000;
$color-accent: #fff;

$background: #F9F9F9;
$background-accent: #fff;

$color-light: #002852;
$color-dark: #002856;
$color-accent: #559AD0;

$sidenav-color: #fff;
$sidenav-active: #CBCFD9;

