@import './colors.scss';


.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  h1 {
    width: 100%;
    margin-bottom: 20px;
  }

  :global {
    .btn {
      position: absolute;
      right: 0px;
      top: 40px;
    }
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;


  .card {
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: rgba(227, 227, 227, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    box-shadow: 10px 10px 68px -10px rgba(0,0,0,0.15);


    .site {
      position: relative;
      width: 100%;

      img {
        margin-bottom: -5px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        // height: 210px;
      }
    }

    .overlay:hover {
      opacity: 0.75;
      cursor: pointer;
    }

    .dot {
      display: block;
      height: 10px;
      width: 100%;
    }


    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 101%;
      width: 100%;
      opacity: 0;
      transition: .3s ease;
      background-color: #eee;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bolder;
      font-size: 2rem;
      text-transform: uppercase;
    }


    i {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      background-color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      z-index: 50;
    }

    .title {
      text-align: center;
      padding: 20px 10px;
      font-size: 14px;
      width: 100%;
      height: 100%;
      background-color: #fff;
      color: #000;
      position: relative;
      display: flex;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      justify-content: space-between;
      align-items: center;


      .name {
        font-size: 1.1rem;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .title {
    flex-direction: column;
    align-items: flex-start;

    h1 {
      margin-bottom: 0;
    }

    :global {
      .btn {
        position: initial;
        width: 100%;
        margin: 20px 0;
      }
    }
  }
}
