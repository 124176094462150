@import './colors.scss';

.header {
  position: sticky;
  top: 0;
  z-index: 2000;
}

.dropdown {
  color: #fff;

  ul {
    display: flex;
    justify-content: center;
    flex-direction: column;

    li a {
      color: #009fe2;
    }
  }
}

.aside {
  width: 250px;
  max-width: 0;
  position: fixed;
  top: 100px;
  left: 0;
  height: calc(100% - 100px);
  background-color: #fff;
  z-index: 100;
  transition: max-width 0.3s;
  padding-top: 10px;

  .langOption {
    padding-left: 40px;
  }

  ul {
    li {
      min-height: 44px;
      padding: 15px 10px;
      cursor: pointer;
      border-bottom: 1px solid #f4f4f4;



      i {
        margin-left: 20px;
        color: rgba(0,0,0,.54);
      }

      a {
        text-decoration: none;
        color: #000;
        padding: 0 10px;
        display: grid;
        grid-template-columns: 1fr 40px;
      }
    }
  }
}

.sidenavOverlay {
  width: 100%;
  position: fixed;
  top: 100px;
  left: 0;
  height: calc(100% - 100px);
  z-index: 50;
  background-color: rgba(0,0,0, 0.5);
}

nav {
  background-color: black;
  min-height: 100px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    color: #fff;
    cursor: pointer;
  }


  li a:hover {
    color: #009fe2;
  }

  li {
    text-transform: capitalize;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    
    li {
      transition: background-color .3s;
      font-size: 1rem;
      color: #fff;
      display: block;
      padding: 0 15px;
      cursor: pointer;
    }

    li, li a {
      color: #fff;
      text-decoration: none;
    }

    i {
      margin-left: 20px;
    }
  }
}

.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
}

.languageTrigger {
  position: relative;
  user-select: none;
  &:hover {
    color: #039be5;
  }
}

.clickToClose {
  min-height: 100vh;
  background-color: rgba(0,0,0, 0.3);
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  left: 0;
}

.languageDropdown {
  position: absolute;
  top: 20px;
  right: 0;
  height: 100px;
  min-width: 100px;
  cursor: pointer;
  z-index: 1000;
  background-color: #fff;
  flex-direction: column;
  height: max-content;

  li {
    font-weight: 500;
    cursor: pointer;
    border-bottom: 2px solid #F9F9F9;
    padding: 15px;
    margin-right: 0;
    width: 100%;
    color: #000;

    &:hover {
      background-color: $color-dark;
      color: #fff;

    }
  }
}
