@import './colors.scss';

.step {
  background-color: $color-dark;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 20px;
}

figure {
  position: relative;
  background-color: #fff;
  margin: 0;
  border-radius: 10px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  i {
    font-size: 3rem;
    color: $color-dark;
    margin-bottom: 20px;
  }
}

figcaption {
  color: $color-dark;
  font-size: 20px;
  text-align: center;
  width: 80%;
  margin-top: 20px;
  
}

.icons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  margin-bottom: 50px;

}


// iphone 7/8 plus
@media only screen and (max-width: 414px) {
  .icons {
    grid-template-columns: 1fr;
  }

};
