@import './reset';
@import './colors';
@import './inputs';

* {
  box-sizing: border-box;
}

html {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

input[type="file"] {
  display: none;
}

.searchform {
  position: relative;
  margin-bottom: 20px;

  input {
    height: 50px;
  }

  i {
    background-color: #fff;
    position: absolute;
    right: 10px;
    width: 40px;
    top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
  }
}

.imgPlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

  span {
    margin-top: 40px;
    text-align: center;
    line-height: 1.3;
    font-size: 1.2rem;
  }

  img {
    max-width: 300px !important;
  }
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #F9F9F9;
}

.dropdownContainer {
  position: relative;
  cursor: pointer;

  &:hover {
    color: #039be5 !important;
  }

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .dropdown {
    position: absolute;
    background-color: #fff;
    width: max-content;
    min-width: 100px;
    height: max-content;
    z-index: 50;
    right: 0;
    margin-top: 10px;

    li {
      min-height: 44px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #eee;;
      width: 100%;
      padding: 10px;
      cursor: pointer;
      color: #039be5;

      &:hover  {
        background-color: #eee;
      }
    }
  }
}

.warning {
  background-color: orangered;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.bell {
  cursor: pointer;
  padding: 0 20px;
  position: relative;
  color: #fff;

  .amount {
    position: absolute;
    background-color: red;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    top: -15px;
    right: 0;
  }
}

.spinnerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.checked_image {
  margin: 20px auto;
  display: block;
}

.flatpickr-current-month {
  display: flex !important;
  justify-content: space-between !important;
}

.info-page  {
  padding: 20px;
  line-height: 1.4rem;
  font-size: 1.1rem;
  background-color: #f5f5f5;
  margin-bottom: 30px;
  border-radius: 4px;

  span {
    display: initial !important;
  }

  i {
    margin-right: 10px;
  }
}

.errorinfo {
  color: #fff;
  background-color: $warning;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;

  .errortitle {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 10px;
    color: #fff;
  }

  h2 {
    color: #fff;
  }

  .buttons {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
  }


  .btn {
    background-color: #fff;
    color: $warning;
  }

  strong {
    font-weight: bold;
    color: #fff;
  }

  ul, li{
    list-style: square !important;
    padding: 5px;
  }


  li {
    margin-left: 15px;
  }
}

main {
  flex: 1 0 auto;
  padding-bottom: 50px; 
  width: 90%;
  margin: 0 auto;
}

.custom-dropdown {
    margin-left: auto;
    top: 2px !important;
    right: 2px !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat; 
}

.sidenav-overlay {
  z-index: 996;
}

.tabs .indicator {
    background-color: #039be5;
}

.tabs .tab.disabled a,
.tabs .tab.disabled a:hover,
.tabs .tab a {
    color: #039be5 !important;
}


.customCollapsible {
  // border:1px solid #eee !important;

  li {
    border-bottom: 1px solid #f9f9f9;
  }

  li:nth-of-type(1) {
    background-color: #009fe2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff;
  }
};

.inputError {
  i {
    font-size: 1.2rem;
    margin-right: 5px;
  }

  color: #f44336;
  margin-bottom: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
}


.sharedUsers, .deviceList, .codes {
    box-shadow: none;
    border: none;
    li {
        background-color: inherit;
        i {
            font-size: 1rem;
        }
    }
}

.sharedUsers {
  li {
    display: grid;
    grid-template-columns: repeat(2, 1fr) 40px;
  }
}


.codes {
  li {
    display: grid;
    grid-template-columns: 100px 1fr !important;
  }
}

.deviceList {
  li {
    grid-template-columns: repeat(3, 1fr) 40px 40px;
    display: grid;
  }
}

.cropperSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.cropper-in-modal {
  min-height: 100% !important;
  width: 100% !important;
  top: 0 !important;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;

  .footer-image:nth-of-type(1) {
    margin-right: 40px;
    width: 140px;
    margin-top: 5px;
    img {
      width: 150px;
    }
  }
}

.placeholder {
  padding: 20px;
  background-color: #fff;
  color: grey;

  h2 {
    font-size: 1.5rem;
  }
}

.title  {
  font-size: 40px;
  font-weight: 200;
  color: #009fe2;
  border-bottom: 1px solid #009fe2;
  margin-top: 60px;
  margin-bottom: 50px;
  padding-bottom: 5px;
}

.cta {
  margin-bottom: 30px;
  display: flex;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 1rem;
    }
    span {
      margin-left: 10px;
    }
  }
}


.btn {
  color: #fff;
  background-color: #2e6da4;
  text-transform: none;
  margin: 5px;
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

  height: 44px;
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
  font-weight: inherit;
  padding: 0 10px;

  span {
    margin-bottom: 0 !important;
  }

  i {
    font-size: 0.7rem;
  }


  &.disabled {
    background-color: #aaa !important;
    color: #444;
    cursor: not-allowed !important;
  }
}

.error {
  background-color: darkred;
}

.succes {
  background-color: green;
}

.mobile {
  display: none;
}

.Toastify__toast-container--top-center {
  z-index: 20000;
  .Toastify__toast--error, .Toastify__toast--success  { 
    width: max-content;
  }
}



@media only screen and (max-width: 900px) {
  .body-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .mobile {
    display: block;
  }

  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 700px) {

  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .copyright {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }

  .page-footer .copyright {
    text-align: center;
  }

  .logos {
    flex-direction: column;
    margin-bottom: 20px;

    .footer-image {
      padding: 10px;
    }
  }

  .modal {
    min-width: 100% !important;
    top: 0 !important;
    min-height: 100%;
  }

  .management {
    grid-template-columns: 1fr;
    .screen, .aside {
      min-height: inherit;
    }
  }

  .cropperSelect img {
    zoom: 0.6;
  }

}

@media only screen and (max-width: 420px) {
    .grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .page-footer .footer-info {
        flex-direction: column;
    }

    main {
      width: 90% !important;
    }

}


